<template>
  <div id="timeline">
    <Header class="" />
    <report
      :type="reportType"
      :resource-id="reportResourceId"
      ref="reportmodel"
    />
    <reactions-modal :profileId="reactionsProfileId" />
    <div class="timeline" style="height: inherit">
      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- SIDEBAR -->
      <timeline-left-filters :mqShallShowLeftSidebar="false" />
      <!-- END SIDEBAR -->

      <section :class="['grid-views', 'd-flex', 'justify-content-center']">
        <div id="postWidth" class="ecommerce-card" no-body>
          <!-- <profile-post :posts="profileData" /> -->
          <img
            src="@/assets/images/rebranding-en.png"
            class="w-100 rounded-lg mb-2"
            alt="Timeline"
            v-if="this.$i18n.locale == 'en'"
          />
          <img
            src="@/assets/images/rebranding-ar.png"
            class="w-100 rounded-lg mb-2"
            alt="Timeline"
            v-if="this.$i18n.locale == 'ar'"
          />
          <new-post-card />

          <div class="text-center" v-if="isLoading">
            {{ $t("Timeline.Loading") }}...
          </div>
          <div class="text-center" v-if="!isLoading && !posts.length">
            {{ $t("Timeline.No more data") }}
          </div>

          <div v-if="posts">
            <vue-loadmore :on-loadmore="loadMore" :finished="isFinished">
              <profile-card
                v-for="(post, index) in posts"
                :key="`profile_card_${index}`"
                :data="post"
                @report="reportResource"
                @showReactions="showReactions"
              ></profile-card>
            </vue-loadmore>
          </div>
        </div>
        <timeline-right-sidebar />
      </section>
    </div>
    <!-- <Footer /> -->
  </div>
</template>
<script>
import store from "@/store";
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BvModal,
} from "bootstrap-vue";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import TimelineLeftFilters from "./TimelineLeftFilters.vue";
import TimelineRightSidebar from "./TimelineRightSidebar.vue";
import useTimeline from "./useTimeline";
import ProfilePost from "@/views/pages/profile/ProfilePost.vue";
import { mapActions, mapGetters } from "vuex";
import ProfileCard from "./ProfileCard";
import Report from "../Report";
import ReactionsModal from "./ReactionsModal.vue";
import NewPostCard from "./NewPostCard.vue";
export default {
  components: {
    Header,
    Footer,
    TimelineLeftFilters,
    TimelineRightSidebar,
    ProfilePost,
    /* Bootstrap */
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    ProfileCard,
    Report,
    ReactionsModal,
    NewPostCard,
  },
  setup() {
    const {
      filterOptions,
      profileType,
      category,
      countries,
      blockchainExchange,
      perPage,
      page,
      fetchPosts,
    } = useTimeline();

    const loadPosts = async () => {
      const data = new FormData();
      data.append("perPage", perPage.value);
      data.append("page", page.value);
      data.append("profileType", profileType.value);
      data.append("category", profileType.value == "" ? "" : category.value);
      data.append("countries", countries.value);
      data.append("blockchainExchange", blockchainExchange.value);
      fetchPosts(data).then((response) => {});
    };

    loadPosts();

    const loadMore = async (done) => {
      page.value += 1;
      loadPosts();
      done();
    };

    return {
      filterOptions,
      loadMore,
    };
  },
  computed: {
    ...mapGetters({
      posts: "timeline/getPosts",
      postsCount: "timeline/getPostsCount",
      isLoading: "timeline/getIsLoading",
      postsLoaded: "timeline/getPostsLoaded",
      isFinished: "timeline/getIsFinished",
    }),
  },
  data() {
    return {
      reportType: "profile",
      reportResourceId: "",
      reportShow: false,
      reactionsProfileId: "",
    };
  },
  methods: {
    ...mapActions({
      fetchPosts: "timeline/getPostsData",
    }),
    reportResource: function (type, resourceId) {
      this.reportType = type;
      this.reportResourceId = resourceId;
      this.$bvModal.show("reportmodal");
    },
    showReactions: function (profileId) {
      this.reactionsProfileId = profileId;
      this.$bvModal.show("reactionsmodal");
    },
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
<style>
html[dir] .navbar-hidden .app-content {
  padding: 2rem 0px !important;
}
</style>
<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.ecommerce-card {
  min-height: 500px;
}
#postWidth {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  #postWidth {
    width: unset;
  }
}
</style>
<style scoped>
.timeline {
  margin-top: 70px;
}

@media (min-width: 992px) {
  [dir="ltr"] .sidebar-left {
    float: left;
    margin-top: 56px;
  }
}
@media (min-width: 992px) {
  .footer-timeline {
    margin-left: -260px;
  }
}
@media (max-width: 1400px) {
  .timeline-sidebar-right {
    display: none;
  }
  #postWidth {
    margin-left: 280px;
  }
}
@media (max-width: 992px) {
  #postWidth {
    margin-left: auto;
  }
}
</style>
